<template>
  <page-header-wrapper
    :title="`${model.title}`"
    :tab-list="tabList"
    :tab-active-key="tabActiveKey"
    @tabChange="handleTabChange"
  >
    <template v-slot:content>
      <a-descriptions class="myDescription" size="small" :column="isMobile ? 1 : 2">
        <a-descriptions-item label="项目名称">{{ processForm['项目名称'] | textFilter }}</a-descriptions-item>
        <a-descriptions-item label="业主方">{{ processForm['卖方/出租方'] | textFilter }}</a-descriptions-item>
        <a-descriptions-item label="业主方身份证号">{{ processForm['业主身份证'] | textFilter }}</a-descriptions-item>
        <a-descriptions-item label="业主联系电话">{{ processForm['业主联系电话'] | phoneFilter }}</a-descriptions-item>
        <a-descriptions-item label="业态">{{ processForm['业态'] | textFilter }}</a-descriptions-item>
        <a-descriptions-item label="物业区域">{{ processForm['物业区域'] | textFilter }}</a-descriptions-item>
        <a-descriptions-item label="上级分销渠道">{{ processForm['上级分销渠道'] | textFilter }}</a-descriptions-item>
        <a-descriptions-item label="成交房号">{{ processForm['成交房号'] | textFilter }}</a-descriptions-item>
        <a-descriptions-item label="建筑面积">{{ processForm['建筑面积'] | areaFilter }}</a-descriptions-item>
        <a-descriptions-item label="套内面积">{{ processForm['套内面积'] | areaFilter }}</a-descriptions-item>
      </a-descriptions>
    </template>

    <!-- actions -->
    <template v-slot:extra>
      <!-- <a-button-group style="margin-right: 10px;">
        <a-button v-if="btnPermission('member_update')" icon="check" @click="affrim">{{ noticeTitle }}</a-button>
      </a-button-group> -->
      <a-button-group>
        <!-- <a-button type="dashed" icon="redo" @click="update">刷新</a-button> -->
        <a-button type="primary" icon="rollback" @click="navBack">返回</a-button>
      </a-button-group>
    </template>

    <template v-slot:extraContent>
      <a-row class="status-list">
        <a-col :xs="12" :sm="12">
          <div class="text">状态</div>
          <div class="heading">{{ model.status | statusFilter }}</div>
        </a-col>
        <a-col :xs="12" :sm="12">
          <div class="text">创建人</div>
          <div class="heading">{{ model.originator | textFilter }}</div>
        </a-col>
      </a-row>
    </template>

    <a-card :bordered="false" title="审批记录">
      <a-steps :direction="isMobile && 'vertical' || 'horizontal'" :current="processRecords.length" progressDot>
        <a-step v-for="record in processRecords" :key="record.id">
          <template v-slot:title>
            <span>
              {{ record.op_type | flowTypeFilter }}
            </span>
          </template>
          <template v-slot:description>
            <div class="antd-pro-pages-profile-advanced-style-stepDescription">
              {{ record.user.name }}
              <a-badge :text="record.op_result | resultFilter" :color="record.op_result | resultTypeFilter"></a-badge>
              <div>{{ record.created_time }}</div>
            </div>
          </template>
        </a-step>
      </a-steps>
    </a-card>

    <a-card class="myDescription margin-top" :bordered="false" title="项目信息">
      <a-descriptions title="客户信息">
        <a-descriptions-item label="购房/承租方">{{ processForm['购房/承租方'] | textFilter }}</a-descriptions-item>
        <a-descriptions-item label="客户联系电话">{{ processForm['客户联系电话'] | textFilter }}</a-descriptions-item>
        <a-descriptions-item label="客户身份证">{{ processForm['客户身份证'] | textFilter }}</a-descriptions-item>
      </a-descriptions>
      <a-descriptions title="签约信息">
        <a-descriptions-item label="成交途径">{{ processForm['成交途径'] | textFilter }}</a-descriptions-item>
        <a-descriptions-item label="置业顾问">{{ processForm['置业顾问'] | textFilter }}</a-descriptions-item>
        <a-descriptions-item label="经纪人联系电话">{{ processForm['经纪人联系电话'] | phoneFilter }}</a-descriptions-item>
        <a-descriptions-item label="经纪人姓名">{{ processForm['经纪人姓名'] | textFilter }}</a-descriptions-item>
        <a-descriptions-item label="付款方式">{{ processForm['付款方式'] | textFilter }}</a-descriptions-item>
        <a-descriptions-item label="业务类别">{{ processForm['业务类别'] | textFilter }}</a-descriptions-item>
        <a-descriptions-item label="开票规划">{{ processForm['开票规划'] | textFilter }}</a-descriptions-item>
        <a-descriptions-item label="到访时间">{{ processForm['到访时间'] | textFilter }}</a-descriptions-item>
        <a-descriptions-item label="认购时间">{{ processForm['认购时间'] | textFilter }}</a-descriptions-item>
        <a-descriptions-item label="签约日期">{{ processForm['签约日期'] | textFilter }}</a-descriptions-item>
        <a-descriptions-item label="签约总价">{{ processForm['签约总价'] | priceFilter }}</a-descriptions-item>
        <a-descriptions-item label="业绩归属日期">{{ processForm['业绩归属日期'] | textFilter }}</a-descriptions-item>
        <a-descriptions-item label="交易双方备注">{{ processForm['交易双方备注'] | textFilter }}</a-descriptions-item>
        <a-descriptions-item label="备注">{{ processForm['备注'] | textFilter }}</a-descriptions-item>
      </a-descriptions>
      <a-descriptions title="佣金信息">
        <a-descriptions-item label="公司佣金">{{ processForm['公司佣金'] | priceFilter }}</a-descriptions-item>
        <a-descriptions-item label="对外拆佣">{{ processForm['对外拆佣'] | priceFilter }}</a-descriptions-item>
        <a-descriptions-item label="现金奖励">{{ processForm['现金奖励'] | priceFilter }}</a-descriptions-item>
        <a-descriptions-item label="计业绩现金奖励">{{ processForm['计业绩现金奖励'] | priceFilter }}</a-descriptions-item>
        <a-descriptions-item label="公司净佣">{{ processForm['公司净佣'] | priceFilter }}</a-descriptions-item>
      </a-descriptions>
    </a-card>

    <a-card title="成交信息" bordered class="margin-top">
      <a-table
        ref="table"
        size="default"
        :rowKey="record => record.id"
        :columns="finishColumns"
        :data-source="signData"
        showPagination="auto"
      >
        <span slot="name" slot-scope="text">{{ text | textFilter }}</span>
        <span slot="group" slot-scope="text">{{ text | textFilter }}</span>
        <span slot="performance" slot-scope="text">{{ text | priceFilter }}</span>
      </a-table>
    </a-card>

    <a-card title="分单信息" :bordered="false" class="margin-top">
      <distributor ref="Distributor" />
    </a-card>
    <a-card v-if="type > 1" :title="`公司佣金：${this.preNumberFormat(this.processForm['公司佣金'])}`" :bordered="false" class="margin-top">
      <invoice ref="Invoice" />
    </a-card>
    <a-card v-if="type > 2" :title="`公司佣金：${this.preNumberFormat(this.processForm['公司佣金'])}`" :bordered="false" class="margin-top">
      <payback ref="Payback" />
    </a-card>
    <a-card v-if="type > 3" :title="`公司佣金：${this.preNumberFormat(this.processForm['公司佣金'])}`" :bordered="false" class="margin-top">
      <distribute ref="Distribute" />
    </a-card>
    <a-card v-if="type > 4" :title="`应结佣金额：${this.preNumberFormat(this.processForm['应结佣金额'])}`" :bordered="false" class="margin-top">
      <checkout ref="Checkout" :targetAmount="targetAmount" />
    </a-card>

    <update-affirm ref="UpdateAffirm" :noticeTitle="noticeTitle" @reload="reload" />
  </page-header-wrapper>
</template>

<script>
import { baseMixin } from '@/store/app-mixin'
import { STable, Ellipsis } from '@/components'

import UpdateAffirm from '../components/UpdateAffirm'

import Distributor from './components/Distributor'
import Invoice from './components/Invoice'
import Payback from './components/Payback'
import Distribute from './components/Distribute'
import Checkout from './components/Checkout'

import { instanceGet } from '@/api/moxi'
import { finishColumns, resultMap, flowTypeMap, statusMap } from './js/index'

export default {
  mixins: [baseMixin],
  components: {
    STable,
    Ellipsis,

    Distributor,
    Invoice,
    Payback,
    Distribute,
    Checkout,

    UpdateAffirm
  },
  created() {
    const query = this.$route.query
    if (query.id) this.id = query.id
    if (query.type) this.type = query.type
  },
  data () {
    return {
      id: null,
      type: 1,

      model: {},
      processForm: {},
      processRecords: {},
      targetAmount: 0,

      signData: [],
      
      finishColumns,

      tabList: [
        { key: 'detail', tab: '详情' }
      ],
      tabActiveKey: 'detail'
    }
  },
  computed: {
    dingUserId() {
      return this.$store.state.dingUser.data.user_id
    },
    companyCommissionTitle() {
      return `公司佣金：${this.preNumberFormat(this.processForm['公司佣金'])}`
    },
    commissionTitle() {
      return `应结佣金额：${this.preNumberFormat(this.processForm['应结佣金额'])}`
    },
    noticeTitle() {
      switch (this.type) {
        case '3':
          return '财务回款'
        case '4':
          return '财务拆佣'
        case '5':
          return '员工结佣'
        default:
          return '财务开票'
      }
    }
  },
  filters: {
    statusFilter(type) {
      return statusMap[type].text
    },
    flowTypeFilter(type) {
      return flowTypeMap[type].text
    },
    resultFilter(type) {
      return resultMap[type].text
    },
    resultTypeFilter(type) {
      return resultMap[type].color
    }
  },
  mounted() {
    this.reload()
  },
  methods: {
    update() {
      this.reload()
      this.$refs.Distributor.reload()
      this.$refs.Invoice.reload()
      this.$refs.Payback.reload()
      this.$refs.Distribute.reload()
      this.$refs.Checkout.reload()
    },
    reload() {
      instanceGet(this.dingUserId, this.id).then(res => {
        if (res.success) {
          const data = res.data
          const processForm = data.process_form
          this.model = data

          let key = ''
          for (key in processForm) {
            this.processForm[key] = processForm[key].value
          }
          
          this.processRecords = data.process_records

          this.targetAmount = processForm['应结佣金额'].value

          this.signData = [
            {
              name: processForm['成交人'].value,
              group: processForm['成交人组别'].value,
              performance: processForm['成交人组别业绩'].value
            }
          ]
        }
        
      })
    },
    affrim() {
      this.$refs.UpdateAffirm.show(this.model)
    },
    handleTabChange (key) {
      this.tabActiveKey = key
    }
  }
}
</script>

<style lang="less" scoped>
  .margin-top {
    margin-top: 24px;
  }
  .text {
    color: rgba(0, 0, 0, .45);
  }

  .heading {
    color: rgba(0, 0, 0, .85);
    font-size: 20px;
  }
</style>
